document.addEventListener('DOMContentLoaded', function () {
  Sceon.AvaliableForChat = {};
  Sceon.AvaliableForChat.url = '/available-for-chat';
  Sceon.AvaliableForChat.container = '#available-for-chat';

  Sceon.AvaliableForChat.init = function () {
    if (
      $(Sceon.AvaliableForChat.container).length &&
      Sceon.AvaliableForChat.url
    ) {
      $.ajax({
        url: Sceon.AvaliableForChat.url,
        type: 'GET',
        success: function (resp) {
          if (resp.html?.trim().length) {
            $(Sceon.AvaliableForChat.container).html(resp.html);
            $(Sceon.AvaliableForChat.container).addClass('open');
          }
        },
        error: function (err) {
          $(Sceon.AvaliableForChat.container).remove();
        },
      });
    }
  };

  Sceon.AvaliableForChat.init();

  Sceon.LatestStatusMessages = {};
  Sceon.LatestStatusMessages.url = '/latest-status-messages';
  Sceon.LatestStatusMessages.container = '#latest-status-messages';

  Sceon.LatestStatusMessages.Load = function (page) {
    if (!$(Sceon.LatestStatusMessages.container).length) {
      return;
    }
    if (page === undefined || page < 1) {
      page = 1;
    }
    let url = Sceon.LatestStatusMessages.url + '?page=' + page;
    Sceon.LatestStatusMessages.Show(url);
  };

  Sceon.LatestStatusMessages.Show = function (url) {
    let lsm = new Sceon.Overlay($(Sceon.LatestStatusMessages.container), {
      loader: '/img/loader-tiny.gif',
      offset: { bottom: 0, top: 0 },
    });

    lsm.enable();

    $.ajax({
      url: url,
      method: 'GET',
      success: function (resp) {
        if (resp?.trim()?.length) {
          $(Sceon.LatestStatusMessages.container).html(resp);
          // $(Sceon.LatestStatusMessages.container).addClass('open');
        } else {
          lsm.disable();
        }
      },
    });
  };

  Sceon.LatestStatusMessages.Load();

  Sceon.HappyHourList = {};
  Sceon.HappyHourList.url = '/happy-hour-ajax';
  Sceon.HappyHourList.container = '#happy-hour-live';

  Sceon.HappyHourList.init = function () {
    if ($(Sceon.HappyHourList.container).length && Sceon.HappyHourList.url) {
      $.ajax({
        url: Sceon.HappyHourList.url,
        method: 'GET',
        success: function (resp) {
          if (resp?.trim()?.length) {
            $(Sceon.HappyHourList.container).html(resp);
            $(Sceon.HappyHourList.container).addClass('open');
            const length =
              $(Sceon.HappyHourList.container).find('.escort-happy-hour-item')
                .length - 1;
            let elm_index = 0;
            $(Sceon.HappyHourList.container)
              .find('.escort-happy-hour-item')
              .first()
              .removeClass('hidden');

            setInterval(() => {
              $(Sceon.HappyHourList.container)
                .find('.escort-happy-hour-item')
                .addClass('hidden')
                .each((index, item) => {
                  if (index === elm_index) {
                    $(item).removeClass('hidden');
                    elm_index = elm_index < length ? elm_index + 1 : 0;
                    return false;
                  }
                });
            }, 10000);
          }
        },
        error: function (err) {
          console.error(err);
        },
      });
    } else {
      return;
    }
  };

  Sceon.HappyHourList.init();
});
